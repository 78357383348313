/* istanbul ignore file */
import React, { useState } from 'react';

import HavenlyAtHomeModalContext, { IHavenlyAtHomeModal } from '@context/Modal/havenly-at-home-modal';
import HavenlyAtHomeModal from '@components/shared/modals/HavenlyAtHomeModal/HavenlyAtHomeModal';
import ZipStoreProvider from '@components/shared/zipcode/ZipStoreProvider';

const HavenlyAtHomeModalProvider: React.FunctionComponent = ({ children }) => {
  const [
    modalState,
    setModalState,
  ] = useState<IHavenlyAtHomeModal | null>(null);

  const onClose = () => {
    setModalState(null);
  };

  return (
    <HavenlyAtHomeModalContext.Provider
      value={{
        open: setModalState,
      }}
    >
      <ZipStoreProvider>
        {modalState && (
          <HavenlyAtHomeModal
            state={modalState}
            onClose={onClose}
          />
        )}
        {children}
      </ZipStoreProvider>
    </HavenlyAtHomeModalContext.Provider>
  );
};

export default HavenlyAtHomeModalProvider;
