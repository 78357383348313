/* istanbul ignore file */

import gql from 'graphql-tag';

interface IUpdateUserInput {
  zipCode?: string;
}

export interface IUpdateUserVariables {
  userId: string | number;
  input: IUpdateUserInput;
}

export interface IUpdateUserData {
  updateUser: {
    id: string;
    zipCode?: string;
  };
}

export default gql`
  mutation UpdateUser(
      $userId: ID!,
      $input: UpdateUserInput!
  ) {
      updateUser(
          userId: $userId
          input: $input
      ) {
          id
          zipCode
      }
  }
`;
