import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { IHavenlyAtHomeModal } from '@context/Modal/havenly-at-home-modal';
import { useTheme } from '@components/theme';
import classNames from 'classnames';
import InputTextLabel from '@components/shared/forms/molecules/InputTextLabel/InputTextLabel';
import UserContext from '@context/User/user-context';
import { useZipStorage, InPersonZipEligibility } from '@components/shared/zipcode/ZipStoreProvider';
import CloseButton from '../shared/CloseButton/CloseButton';
import styles from './HavenlyAtHomeModal.module.scss';
import useAtHomeModalFormState, { AtHomeModalState, IForm, IFormNotice } from './hooks/useAtHomeModalFormState';
import ZipCodeEditDisplay from './ZipCodeEditDisplay/ZipCodeEditDisplay';
import useSubmit from './hooks/useSubmit';

interface IHavenlyAtHomeModalProps {
  state: IHavenlyAtHomeModal;
  onClose: () => void;
}

export default function HavenlyAtHomeModal(
  {
    state: {
      checkoutQuantity
    },
    onClose
  }: IHavenlyAtHomeModalProps
) {
  const theme = useTheme();
  const { storedZip, inPersonEligible } = useZipStorage();
  const { user } = useContext(UserContext);
  const {
    form,
    setForm,
    formNotice,
    setFormNotice,
    formState,
    setFormState
  } = useAtHomeModalFormState();

  const {
    busy,
    handleSubmitFullForm,
    handleSubmitZipForm,
    handleSubmitPassword,
    handleResetPassword
  } = useSubmit({
    form,
    setFormState,
    setFormNotice,
    checkoutQuantity,
  });

  return (
    <Modal
      animation
      dialogClassName={styles.ModalDialog}
      show
      centered
      onHide={onClose}
    >
      <div className={styles.Content}>
        <div className={classNames(
          theme.styles.Title2,
          styles.Title
        )}
        >
          <span data-test="heading-text">{getHeading(inPersonEligible)}</span>
        </div>

        <div
          className={classNames(
            theme.styles.Body,
            styles.Body,
            `${inPersonEligible !== InPersonZipEligibility.NONE ? styles.Animate : ''}`
          )}
        >
          {getMessage(formState, inPersonEligible)}
        </div>

        {formState === AtHomeModalState.ZIP_CODE_DISPLAY && (
          <ZipCodeEditDisplay
            zipCode={storedZip?.code || ''}
            onClick={() => {
              if (user) {
                return setFormState(AtHomeModalState.ZIP_CODE_FORM);
              }
              return setFormState(AtHomeModalState.FULL_FORM);
            }}
          />
        )}

        {formState === AtHomeModalState.FULL_FORM && (
          <FullForm
            busy={busy}
            form={form}
            setForm={setForm}
            onSubmit={handleSubmitFullForm}
            formNotice={formNotice}
            inPersonEligible={inPersonEligible}
          />
        )}

        {formState === AtHomeModalState.PASSWORD_FORM && (
          <PasswordForm
            busy={busy}
            onSubmit={handleSubmitPassword}
            onPasswordReset={handleResetPassword}
            formNotice={formNotice}
          />
        )}

        {formState === AtHomeModalState.ZIP_CODE_FORM && (
          <ZipForm
            busy={busy}
            form={form}
            setForm={setForm}
            onSubmit={handleSubmitZipForm}
            formNotice={formNotice}
            inPersonEligible={inPersonEligible}
          />
        )}

        <CloseButton
          className={styles.CloseButton}
          disabled={busy}
          onClick={onClose}
        />

      </div>
    </Modal>
  );
}

export function getLinks() {
  return {
    eligible: '/in-person',
    notEligible: '/pricing'
  };
}

function CTA({
  inPersonEligible,
  disabled,
}: {
  inPersonEligible?: InPersonZipEligibility;
  disabled: boolean;
}) {
  const theme = useTheme();

  const CTAText = () => {
    if (inPersonEligible === InPersonZipEligibility.ELIGIBLE) {
      return 'Get started in-person';
    }
    if (inPersonEligible === InPersonZipEligibility.INELIGIBLE) {
      return 'Explore Online Packages';
    }
    return 'Submit';
  };

  return (
    <div className={styles.CTAWrapper}>
      <button
        type="submit"
        data-test="at-home-modal-cta"
        className={classNames(
          styles.CTA,
          theme.styles.Button,
          theme.styles.Primary,
          { [theme.styles.Disabled]: disabled }
        )}
        disabled={disabled}
      >
        {CTAText()}
      </button>
    </div>
  );
}

function FormNotice({ formNotice }: {formNotice: IFormNotice | null}) {
  return (
    <>
      {formNotice && (
        <p
          className={classNames(
            { [styles.Error]: formNotice.state === 'error' },
            { [styles.Info]: formNotice.state === 'info' }
          )}
          data-test="form-error"
        >
          {formNotice.message}
        </p>
      )}
    </>
  );
}

interface IFormProps {
  busy: boolean;
  form: IForm;
  setForm: (form: IForm) => void;
  onSubmit: () => void;
  formNotice: IFormNotice | null;
  inPersonEligible: InPersonZipEligibility;
}

function FullForm({
  busy,
  form,
  setForm,
  onSubmit,
  formNotice,
  inPersonEligible
}: IFormProps) {
  return (
    <form
      className={styles.Form}
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <InputTextLabel
        required
        className={styles.Input}
        maxLength={200}
        onChange={(e) => { setForm({ ...form, name: e.target.value }); }}
        title="Name"
        autoFocus
        value={form.name}
        dataTest="name-input"
      />
      <InputTextLabel
        required
        type="email"
        className={styles.Input}
        maxLength={200}
        onChange={(e) => { setForm({ ...form, email: e.target.value }); }}
        title="Email"
        value={form.email}
        dataTest="email-input"
      />
      <InputTextLabel
        required
        className={styles.Input}
        maxLength={200}
        onChange={(e) => { setForm({ ...form, zipCode: e.target.value }); }}
        title="Zip code"
        value={form.zipCode}
        dataTest="zip-input"
      />
      <FormNotice formNotice={formNotice} />
      <CTA
        inPersonEligible={inPersonEligible}
        disabled={busy}
      />
    </form>
  );
}

function ZipForm({
  busy,
  form,
  setForm,
  onSubmit,
  formNotice,
  inPersonEligible
}: IFormProps) {
  return (
    <form
      className={styles.Form}
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <InputTextLabel
        required
        className={styles.Input}
        maxLength={200}
        onChange={(e) => { setForm({ ...form, zipCode: e.target.value }); }}
        title="Zip code"
        value={form.zipCode}
        dataTest="zip-input"
      />
      <FormNotice formNotice={formNotice} />
      <CTA
        inPersonEligible={inPersonEligible}
        disabled={busy}
      />
    </form>
  );
}

interface IPasswordFormProps {
  busy: boolean;
  onPasswordReset: () => void;
  onSubmit: (password: string) => void;
  formNotice: IFormNotice | null;
}

function PasswordForm({
  busy,
  onPasswordReset,
  onSubmit,
  formNotice
}: IPasswordFormProps) {
  const [password, setPassword] = useState('');
  return (
    <form
      className={styles.Form}
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(password);
      }}
    >
      <InputTextLabel
        autoFocus
        required
        type="password"
        className={classNames(styles.Input, styles.PasswordInput)}
        maxLength={50}
        onChange={(e) => setPassword(e.target.value)}
        title="Password"
        value={password}
        dataTest="password-input"
        disabled={busy}
      />
      <button
        type="button"
        onClick={onPasswordReset}
        className={styles.Forgot}
        disabled={busy}
      >
        Forgot your password?
      </button>
      <FormNotice formNotice={formNotice} />
      <p className={styles.QuestionsCopy}>
        If you have any questions, please reach out to
        <a href="mailto:inperson@havenly.com" target="_blank" className={styles.HaHEmail}> inperson@havenly.com</a>
      </p>
      <CTA
        disabled={busy || !password}
      />
    </form>
  );
}

const headings = {
  init:
  <>
    Ready to get started with a local Havenly designer?
  </>,
  eligible:
  <>
    Let’s design a home you love!
  </>,
  notEligible:
  <>
    Coming soon!
  </>
};

const messages = {
  init:
  <>
    Enter your info to find out if in-person Havenly designers are available in your area!
  </>,
  eligible:
  <>
    Great news - we have in-person designers available in your area!
  </>,
  notEligible:
  <>
    Our in-person designers are not available in your area yet.
    You are officially on our waitlist and will be the first to know when we launch near you!
  </>,
  existentUser:
  <>We noticed you already have an account with us, please input your password.</>
};

function getHeading(inPersonEligible: InPersonZipEligibility) {
  if (inPersonEligible === InPersonZipEligibility.ELIGIBLE) {
    return headings.eligible;
  }
  if (inPersonEligible === InPersonZipEligibility.INELIGIBLE) {
    return headings.notEligible;
  }
  return headings.init;
}

function getMessage(
  state: AtHomeModalState,
  inPersonEligible: InPersonZipEligibility
) {
  if (state === AtHomeModalState.PASSWORD_FORM) {
    return messages.existentUser;
  }
  if (inPersonEligible === InPersonZipEligibility.ELIGIBLE) {
    return messages.eligible;
  }
  if (inPersonEligible === InPersonZipEligibility.INELIGIBLE) {
    return messages.notEligible;
  }
  return messages.init;
}
