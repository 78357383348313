import { get } from 'js-cookie';

const getFBTrackingParams = () => {
  return {
    userAgent: navigator.userAgent || '',
    fbp: get('_fbp') || '',
    fbc: get('_fbc') || '',
  };
};
export default getFBTrackingParams;
