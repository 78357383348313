import { useEffect, useState } from 'react';
import logger from '@lib/logger';
import { ZipCode } from '@graphql/shared/queries/in-person-eligibility.graph';
import validateInPersonZipCode from './utils/validateInPersonZipCode';

const log = logger('useInPersonValidation');

export enum InPersonZipEligibility {
  ELIGIBLE = 'ELIGIBLE',
  INELIGIBLE = 'INELIGIBLE',
  NONE = 'NONE'
}

export default function useInPersonValidation(storedZip: ZipCode | null) {
  const [inPersonEligible, setInPersonEligible] = useState<InPersonZipEligibility>(
    InPersonZipEligibility.NONE
  );

  const [busy, setBusy] = useState(false);

  useEffect(() => {
    async function validateZip() {
      if (!storedZip) setInPersonEligible(InPersonZipEligibility.NONE);

      if (storedZip) {
        setBusy(true);
        try {
          const eligible = await validateInPersonZipCode({
            zipCode: storedZip.code,
            trackSubmission: false
          });
          setInPersonEligible(
            eligible
              ? InPersonZipEligibility.ELIGIBLE
              : InPersonZipEligibility.INELIGIBLE
          );
        } catch (error) {
          log.error(error as Error, 'an error occurred validating in person zip code');
        } finally {
          setBusy(false);
        }
      }
    }
    validateZip();
  }, [storedZip]);

  return {
    busy,
    inPersonEligible
  };
}
