import asyncLogin from '@components/havenly/lib/asyncLogin';
import logger from '@lib/logger';

const log = logger('loginUser');

export default async function loginUser(email: string, password: string) {
  let response = null;
  try {
    response = await asyncLogin({
      email,
      password,
    });
  } catch (error: any) {
    log.error(error, 'loginUser');
    throw error;
  }

  return response;
}
