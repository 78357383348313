import React from 'react';
import classNames from 'classnames';
import styles from './InputLabel.module.scss';

export enum InputLabelSize {
  sm,
  md,
  lg
}

interface IInputLabelProps {
  children: any;
  className?: string;
  htmlFor: string;
  size?: InputLabelSize;
  required?: boolean;
}

const InputLabel = ({
  children,
  className,
  htmlFor,
  size,
  required = false,
}: IInputLabelProps) => (
  <label
    className={classNames(
      styles['a-input-label'],
      'd-block',
      className,
      { [styles['-md']]: size === InputLabelSize.md },
      { [styles['-lg']]: size === InputLabelSize.lg },
    )}
    htmlFor={htmlFor}
    data-test="input-label"
  >
    {children}
    {required ? (
      <span
        className={`${styles['a-input-label__required-asterisk']}`}
        data-test="a-input-label__required-asterisk"
      >
        &nbsp;*
      </span>
    ) : null}
  </label>
);

export default InputLabel;
