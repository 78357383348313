import IN_PERSON_ELIGIBILITY_QUERY from '@graphql/shared/queries/in-person-eligibility.graph';
import { getApollo } from '@lib/apollo/apolloClient';
import logger from '@lib/logger';

const log = logger('validateInPersonZipCode');

interface IValidateInPersonZipCode {
  zipCode: string;
  name?: string;
  email?: string;
  trackSubmission?: boolean;
}
export default async function validateInPersonZipCode({
  zipCode,
  name,
  email,
  trackSubmission,
}: IValidateInPersonZipCode): Promise<boolean> {
  const apolloClient = getApollo();

  let response = null;
  try {
    response = await apolloClient.query({
      query: IN_PERSON_ELIGIBILITY_QUERY,
      variables: {
        zipCode,
        name,
        email,
        trackSubmission,
      },
    });
  } catch (error: any) {
    log.error(error);
  }

  return response?.data?.getInHomeEligibility?.eligible ?? false;
}
