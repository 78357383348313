/* istanbul ignore file */
import fetch from 'isomorphic-unfetch';
import logger from '@lib/logger';
import { safeParseJson } from '@lib/json';

interface IArgs {
  email: string;
  redirectUrl?: string;
}

export default async function asyncSendPasswordReset({ email, redirectUrl }: IArgs) {
  try {
    return await fetch('/async-send-password-reset-notification', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json'
      },
      body: JSON.stringify({
        email,
        redirectUrl
      }),
    }).then(async (response) => {
      const json = safeParseJson(await response.text());
      json.status = response.status;
      if (!response.ok) {
        throw json;
      }
      return json;
    });
  } catch (error: any) {
    logger('asyncSendPasswordReset').error(error);

    throw error;
  }
}
