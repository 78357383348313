/* eslint-disable sort-keys */
import { invert } from 'lodash';

export const FULL = 'FULL';
export const MINI = 'MINI';
export const AT_HOME = 'AT HOME';

export const FULL_DISPLAY = 'Full';
export const MINI_DISPLAY = 'Mini';
export const AT_HOME_DISPLAY = 'Havenly In-Person';

export const roomPackagesById: KeyedObject = {
  1: FULL,
  2: MINI,
  6: AT_HOME
};

export const roomPackageDisplayNamesById: KeyedObject = {
  1: FULL_DISPLAY,
  2: MINI_DISPLAY,
  6: AT_HOME_DISPLAY
};

export const roomPackageDisplayTrackingNamesById: KeyedObject = {
  1: 'full',
  2: 'mini',
  6: 'at_home'
};

export const roomPackagesByName = invert(roomPackagesById);

enum RoomPackages {
  'FULL' = 1,
  'MINI' = 2,
  'AT_HOME' = 6, // @deprecated
  'IN_PERSON' = 6
}

export default RoomPackages;
