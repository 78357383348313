import { RichText, RichTextBlock } from 'prismic-reactjs';
import { IPrismicImage } from '@lib/prismic/types';

export function asText(field: RichTextBlock[] | any[], defaultValue: string = '') {
  if (field?.length) {
    return RichText.asText(field) || defaultValue;
  }

  return defaultValue;
}

/**
 * If a user does not upload an image in Prismic, we don't get null or
 * undefined for a field, we get an empty object (i.e. no url, dimensions,
 * etc.). This method checks that the given image has all the fields we
 * need to render it and returns the image itself.  If essential information
 * is missing, returns null.
 *
 * @param image The image to validate and return, if possible.
 */
export function optionalImage(image: IPrismicImage | undefined | null) {
  if (image?.url && image?.dimensions?.width && image?.dimensions?.height) {
    return image;
  }
  return null;
}
