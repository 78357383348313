/* istanbul ignore file */
import fetch from 'isomorphic-unfetch';
import logger from '@lib/logger';
import getFBTrackingParams from '@lib/getFBTrackingParams/getFBTrackingParams';
import analyticsTrack from '@lib/analytics/analyticsTrack';
import Cookie from 'js-cookie';

import { HAVENLY_ANONYMOUS_ID_COOKIE } from '@lib/cookie/havenlyAnonymousId';
import { safeParseJson } from '@lib/json';

interface IRegisterArgs {
  name: string;
  email: string;
  password?: string;
  source?: string;
  skipPassword?: boolean;
  userLocale?: string;
}

const asyncRegister = async ({
  name, email, password, source, skipPassword = false, userLocale
}: IRegisterArgs) => {
  try {
    const fbTrackingParams = getFBTrackingParams();

    const body: Record<string, any> = {
      name,
      email,
      source,
      password,
      includeUserData: true,
      userLocale, // for user name validation.
      ...fbTrackingParams,
    };

    if (skipPassword) {
      body.skipPassword = true;
      delete body.password;
    }

    const res = await fetch('/async-register', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json'
      },
      body: JSON.stringify(body),
    }).then(async (response) => {
      const json = await safeParseJson(await response.text());
      if (!response.ok) {
        throw json;
      }
      return json;
    });

    analyticsTrack('account_created_fe', {
      havenly_anonymous_id: Cookie.get(HAVENLY_ANONYMOUS_ID_COOKIE)
    });

    return res;
  } catch (error: any) {
    logger('asyncRegister').error(error);

    throw error;
  }
};
export default asyncRegister;
