/* istanbul ignore file */
import fetch from 'isomorphic-unfetch';
import logger from '@lib/logger';
import { safeParseJson } from '@lib/json';
import env from '@lib/envs/env';

interface ILoginArgs {
  email: string;
  password: string;
}

export default async function asyncLogin({ email, password }:ILoginArgs) {
  try {
    const loginUrl = env.LOCAL_TO_DEV_REMOTE ? '/api/login' : '/async-login';
    return await fetch(loginUrl, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json'
      },
      body: JSON.stringify({
        email,
        password,
      }),
    }).then(async (response) => {
      const json = safeParseJson(await response.text());
      json.status = response.status;
      if (!response.ok) {
        throw json;
      }
      return json;
    });
  } catch (error: any) {
    logger('asyncLogin').error(error);

    throw error;
  }
}
