import { IUserContext } from '@context/User/user-context';
import { IAuthResponse } from '@models/shared/i-auth-response';
import logger from '@lib/logger';
import asyncRegister from '@components/havenly/lib/asyncRegister';
import asyncSendPasswordReset from '@components/havenly/lib/asyncSendPasswordReset';

const log = logger('registerAndAuthUser');

export interface AuthUserStatus {
  success: boolean;
  errorMessage: string;
  existentUser?: boolean;
  user?: IAuthResponse;
}
export default async function registerAndAuthUser(
  userContext: IUserContext,
  name: string,
  email: string
): Promise<AuthUserStatus> {
  if (!name) {
    return { success: false, errorMessage: 'The name is required' };
  }
  if (!email) {
    return { success: false, errorMessage: 'The email address is required' };
  }

  try {
    await registerUser(name, email);
  } catch (error: any) {
    if (error.message) {
      if (error.message.includes('email already exists')) {
        return { success: true, errorMessage: '', existentUser: true };
      } else {
        log.error(error, 'registerAndAuthUser');
        return { success: false, errorMessage: 'There was an error, please double check email address' };
      }
    }
    if (error.validationErrors) {
      const msg = error.validationErrors.email || error.validationErrors.name;
      return { success: false, errorMessage: msg };
    }
  }

  const authedUser = await userContext.login();
  if (!authedUser) {
    log.error({}, 'Cannot auth user');
    return { success: false, errorMessage: 'There was an error, please try again' };
  }

  return { success: true, errorMessage: '', user: authedUser };
}

export async function sendPasswordReset(email: string) {
  let response = null;
  try {
    response = await asyncSendPasswordReset({
      email,
      redirectUrl: '/in-person#get-started'
    });
  } catch (error: any) {
    log.error(error, 'sendPasswordReset');
    throw error;
  }

  return response;
}

async function registerUser(name: string, email: string) {
  let response = null;
  try {
    response = await asyncRegister({
      name,
      email,
      skipPassword: true,
    });
  } catch (error: any) {
    log.error(error, 'registerUser');
    throw error;
  }

  return response;
}
