import UserContext from '@context/User/user-context';
import { useContext, useState } from 'react';
import { useRouter } from 'next/router';
import { getApollo } from '@lib/apollo/apolloClient';
import logger from '@lib/logger';
import { useZipStorage } from '@components/shared/zipcode/ZipStoreProvider';
import validateInPersonZipCode from '@components/shared/zipcode/utils/validateInPersonZipCode';
import UpdateUserMutation, { IUpdateUserVariables, IUpdateUserData } from '@graphql/User/mutations/update-user.graphql';
import registerAndAuthUser, { sendPasswordReset } from '../utils/registerAndAuthUser';
import loginUser from '../utils/loginUser';
import getAtHomeCheckoutLink from '../utils/getAtHomeCheckoutLink';
import { AtHomeModalState, IForm, IFormNotice } from './useAtHomeModalFormState';

const log = logger('useSubmit');

export default function useSubmit({
  form,
  setFormState,
  setFormNotice,
  checkoutQuantity,
}: {
  form: IForm;
  setFormState: (formState: AtHomeModalState) => void;
  setFormNotice: (formNotice: IFormNotice | null) => void;
  checkoutQuantity?: number;
}) {
  const [busy, setBusy] = useState(false);
  const router = useRouter();
  const userContext = useContext(UserContext);
  const { user } = userContext;
  const { storeZip } = useZipStorage();

  const redirect = () => {
    return router.push(getAtHomeCheckoutLink(checkoutQuantity));
  };

  const handleSubmitFullForm = async () => {
    try {
      const {
        name, email, zipCode
      } = form;
      if (!name) {
        setFormNotice({
          state: 'error',
          message: 'Name is required'
        });
        return;
      }
      if (!email) {
        setFormNotice({
          state: 'error',
          message: 'Email is required'
        });
        return;
      }
      if (!zipCode) {
        setFormNotice({
          state: 'error',
          message: 'Zip code is required'
        });
        return;
      }

      setBusy(true);
      const zipEligible = await validateInPersonZipCode({
        zipCode,
        name,
        email,
      });

      if (!zipEligible) {
        await storeZip({ code: zipCode });
        setFormState(AtHomeModalState.ZIP_CODE_DISPLAY);
        setBusy(false);
        return;
      }

      const userAuthStatus = await registerAndAuthUser(userContext, name, email);
      if (!userAuthStatus.success) {
        setFormNotice({
          state: 'error',
          message: userAuthStatus.errorMessage
        });
        setBusy(false);
        return;
      }

      if (userAuthStatus.existentUser) {
        setFormNotice(null);
        setFormState(AtHomeModalState.PASSWORD_FORM);
        setBusy(false);
        return;
      }

      await Promise.all([
        storeZip({ code: zipCode }),
        // super annoying that we can't rely on storeZip to update the user
        // b/c this callback is still holding the unauthed userContext object
        // i both love and hate react hooks.
        updateUser({ zipCode }, userAuthStatus.user?.id)
      ]);
      redirect();
    } catch (err) {
      log.error(err as Error);
      setBusy(false);
    }
  };

  const handleSubmitZipForm = async () => {
    if (!user) {
      log.warn('#handleSubmitZipForm should only be called when authenticated');
      return;
    }
    try {
      const {
        zipCode
      } = form;

      if (!zipCode) {
        setFormNotice({
          state: 'error',
          message: 'Zip code is required'
        });
        return;
      }

      setBusy(true);
      const {
        name,
        email
      } = user;
      const zipEligible = await validateInPersonZipCode({
        zipCode,
        name,
        email,
      });

      if (!zipEligible) {
        await storeZip({ code: zipCode });
        setFormState(AtHomeModalState.ZIP_CODE_DISPLAY);
        setBusy(false);
        return;
      }

      await storeZip({ code: zipCode });
      redirect();
    } catch (err) {
      log.error(err as Error);
    }
  };

  const handleSubmitPassword = async (password: string) => {
    const {
      email, zipCode
    } = form;
    setBusy(true);
    try {
      await loginUser(email, password);
    } catch (error: any) {
      if (error?.status && error.status === 401) {
        setFormNotice({
          state: 'error',
          message: 'Password was not recognized. Please try again.'
        });
      } else {
        setFormNotice({
          state: 'error',
          message: 'There was an error, please try again.'
        });
      }
      setBusy(false);
      return;
    }

    const authedUser = await userContext.login();
    if (!authedUser) {
      log.error({}, 'Cannot auth user');
      setFormNotice({
        state: 'error',
        message: 'There was an error, please try again.'
      });
      setBusy(false);
      return;
    }

    await storeZip({ code: zipCode });
    redirect();
  };

  const handleResetPassword = async () => {
    const {
      email
    } = form;
    setBusy(true);

    try {
      const resetResponse = await sendPasswordReset(email);
      setFormNotice({
        state: 'info',
        message: resetResponse.message
      });
    } catch (error: any) {
      setFormNotice({
        state: 'error',
        message: 'There was an error. Please try again.'
      });
    } finally {
      setBusy(false);
    }
  };

  return {
    busy,
    handleSubmitFullForm,
    handleSubmitZipForm,
    handleSubmitPassword,
    handleResetPassword
  };
}

/**
 * unfortunately have to do things janky like this for the full form because
 * it needs to authenticate then update the authenticated user all in the same callback.
 * this means the user context in scope is not the current user context,
 * it's holding the previous user context by closure
 *
 * this function circumvents that by grabbing the apollo client singleton directly
 * and passing in the user id from the login response.
 */
async function updateUser(
  input: {
    zipCode: string;
  },
  userId?: string | number,
) {
  if (!userId) {
    log.warn('#updateUser cannot update authed user');
    return;
  }
  const apolloClient = getApollo();

  try {
    await apolloClient.mutate<IUpdateUserData, IUpdateUserVariables>({
      mutation: UpdateUserMutation,
      variables: {
        userId,
        input
      },
    });
  } catch (error) {
    log.error(error as Error);
  }
}
