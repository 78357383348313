/**
 * Works around the occasional PHP glitch where it generates HTML in the
 * response body ahead of the valid JSON.
 *
 * @param data The raw text data to parse
 */
// eslint-disable-next-line import/prefer-default-export
export function safeParseJson(data: string) {
  try {
    return JSON.parse(data.replace(/^<.*>/, ''));
  } catch (err) {
    return data;
  }
}
