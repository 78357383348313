export const LIVING_ROOM = 1;
export const DINING_ROOM = 2;
export const BEDROOM = 3;
export const NURSERY = 4;
export const OFFICE = 5;
export const OTHER = 6;
export const KITCHEN = 10;
export const BATHROOM = 11;
export const MUDROOM = 12;
export const ENTRY_WAY = 13;
export const PATIO = 14;
export const STUDIO = 15;
export const I_DONT_KNOW = 16;
export const NEW_ROOM = 17;
export const READING_ROOM = 18;
export const PLAYROOM = 19;

export const UGLYNAMES = [
  OTHER,
  NEW_ROOM,
  I_DONT_KNOW,
];

export default {
  LIVING_ROOM,
  DINING_ROOM,
  BEDROOM,
  NURSERY,
  OFFICE,
  OTHER,
  KITCHEN,
  BATHROOM,
  MUDROOM,
  ENTRY_WAY,
  PATIO,
  STUDIO,
  I_DONT_KNOW,
  NEW_ROOM,
  READING_ROOM,
  PLAYROOM
};
