import { useTheme } from '@components/theme';
import classNames from 'classnames';
import React from 'react';
import EditSVG from './edit.svg';

import styles from './ZipCodeEditDisplay.module.scss';

interface IZipCodeEditDisplayProps {
  zipCode: string;
  onClick: () => void;
  className?: string;
}

const ZipCodeEditDisplay = ({ zipCode, onClick, className }: IZipCodeEditDisplayProps) => {
  const theme = useTheme();
  return (
    <div className={ styles.Container }>
      <div className={classNames(
        theme.styles.Body,
        styles.Root,
        className
      )}
      >
        Your zip code is <b>{ zipCode }</b>.
        <button
          type="button"
          onClick={onClick}
        >
          <EditSVG /> Edit
        </button>
      </div>
    </div>
  );
};

export default ZipCodeEditDisplay;
