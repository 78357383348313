import React from 'react';

export interface IHavenlyAtHomeModal {
  checkoutQuantity?: number;
}

export interface IHavenlyAtHomeModalContext {
  open: (state: IHavenlyAtHomeModal) => void;
}

/* istanbul ignore next */
const HavenlyAtHomeModalContext = React.createContext<IHavenlyAtHomeModalContext>({
  open: () => null,
});

export default HavenlyAtHomeModalContext;
