import React from 'react';
import classNames from 'classnames';
import styles from './InputValidationFeedback.module.scss';

interface IInputValidationFeedbackProps {
  className?: string;
  'data-test'?: string;
  invalidMessage?: string;
  isValid?: boolean;
  validMessage?: string;
}

export default function InputValidationFeedback({
  className,
  'data-test': dataTest = 'a-input-validation-feedback',
  invalidMessage,
  isValid,
  validMessage,
}: IInputValidationFeedbackProps) {
  const validationClasses = classNames(
    styles['a-input-validation-feedback'],
    className,
    { [styles['-is-valid']]: isValid === true && validMessage },
    { [styles['-is-invalid']]: isValid === false && invalidMessage },
  );
  const getValidationMessage = () => {
    if (isValid === false && invalidMessage) {
      return invalidMessage;
    }
    if (isValid === true && validMessage) {
      return validMessage;
    }
    // want to preserve the space so something needs to be here even though visibility is hidden
    return 'hidden';
  };
  return (
    <div
      className={validationClasses}
      data-test={dataTest}
    >
      {getValidationMessage()}
    </div>
  );
}
