import classNames from 'classnames';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import InputLabel, { InputLabelSize } from '../../atoms/InputLabel/InputLabel';
import InputText from '../../atoms/InputText/InputText';
import InputValidationFeedback from '../../atoms/InputValidationFeedback/InputValidationFeedback';

interface IInputTextLabel {
  autoFocus?: boolean;
  capitalizeLabel?: boolean;
  className?: string;
  disabled?: boolean;
  inputLabelSize?: InputLabelSize;
  invalidMessage?: string;
  isValid?: boolean;
  maxLength?: number;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  required?: boolean;
  title: string;
  type?: string;
  validMessage?: string;
  value: string;
  dataTest?: string;
}

const InputTextLabel = ({
  autoFocus,
  capitalizeLabel = false,
  className,
  disabled = false,
  invalidMessage,
  isValid,
  inputLabelSize,
  maxLength,
  onBlur = () => {},
  onChange,
  onFocus = () => {},
  onKeyPress = () => {},
  placeholder,
  required = false,
  title,
  type = 'text',
  validMessage,
  value,
  dataTest,
}: IInputTextLabel) => {
  const inputTextLabelClasses = classNames(
    'm-input-text-label',
    className,
  );

  const inputLabelClasses = classNames(
    'mb-2',
    { 'text-capitalize': capitalizeLabel },
  );

  const inputId = uuidv4();

  return (
    <div
      data-test="m-input-text-label"
      className={inputTextLabelClasses}
    >
      <InputLabel
        className={inputLabelClasses}
        data-test="m-input-text-label__input-label"
        htmlFor={inputId}
        size={inputLabelSize}
        required={required}
      >
        {title}
      </InputLabel>

      <InputText
        autoFocus={autoFocus}
        disabled={disabled}
        data-test={dataTest || 'm-input-text-label__input-text'}
        id={inputId}
        isValid={isValid}
        maxLength={maxLength}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        type={type}
        value={value}
      />

      <InputValidationFeedback
        className={`mt-1`}
        data-test="m-input-text-label__input-validation-feedback"
        invalidMessage={invalidMessage}
        isValid={isValid}
        validMessage={validMessage}
      />
    </div>
  );
};

export default InputTextLabel;
