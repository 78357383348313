import gql from 'graphql-tag';

export interface ZipCode {
  code: string;
}

export default gql`
  query HavenlyAtHomeEligibility(
    $zipCode: String!
    $name: String
    $email: String
  ) {
    getInHomeEligibility(zipCode: $zipCode, name: $name, email: $email) {
      eligible
    }
  }
`;
