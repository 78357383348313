import React, { useContext, useEffect } from 'react';
import UserContext from '@context/User/user-context';
import { ZipCode } from '@graphql/shared/queries/in-person-eligibility.graph';
import useLocalStorage from '@hooks/UseLocalStorage/UseLocalStorage';
import useUpdateAuthenticatedUser from '@hooks/User/useUpdateAuthenticatedUser';
import useInPersonValidation, { InPersonZipEligibility } from './useInPersonValidation';

export type { ZipCode } from '@graphql/shared/queries/in-person-eligibility.graph';
export { InPersonZipEligibility } from './useInPersonValidation';

const STORAGE_KEY = 'zipCode';

interface IZipStoreContext {
  storedZip: ZipCode | null;
  inPersonEligible: InPersonZipEligibility;
  busy: boolean;
  getZip: () => ZipCode | null;
  storeZip: (zipCode: ZipCode) => Promise<void>;
  clearZip: () => void;
}

const ZipStoreContext = React.createContext<IZipStoreContext>({
  storedZip: null,
  inPersonEligible: InPersonZipEligibility.NONE,
  busy: false,
  getZip: () => null,
  storeZip: () => Promise.resolve(),
  clearZip: () => {}
});

export function useZipStorage() {
  return useContext(ZipStoreContext);
}

/**
 * Provides local storage zip code.
 * If authenticated, initializes local storage zip code from user context (if zip code set)
 * If authenticated, calls graph mutation to set authenticated user's zip code.
 * Also provides in person eligibility
 */
export default function ZipStoreProvider({ children }: { children?: any }) {
  const {
    value: storedZip,
    setValue,
  } = useLocalStorage<ZipCode | null>(STORAGE_KEY, null);

  useInitializeZipFromUserContext(storedZip, setValue);

  const { storeZip, busy: storeZipBusy } = useStoreZip(setValue);

  const {
    inPersonEligible,
    busy: inPersonEligibleBusy
  } = useInPersonValidation(storedZip);

  return (
    <ZipStoreContext.Provider
      value={{
        busy: inPersonEligibleBusy || storeZipBusy,
        storedZip,
        inPersonEligible,
        getZip: () => storedZip,
        storeZip,
        clearZip: () => setValue(null)
      }}
    >
      {children}
    </ZipStoreContext.Provider>
  );
}

/**
 * returns a storeZip function that sets zipCode both in local storage and also db if authenticated
 */
function useStoreZip(
  setValue: (value: ZipCode) => void
) {
  const {
    updateAuthenticatedUser,
    busy
  } = useUpdateAuthenticatedUser();

  const storeZip = async (zipCode: ZipCode) => {
    setValue(zipCode);
    await updateAuthenticatedUser({ zipCode: zipCode.code });
  };

  return {
    storeZip,
    busy
  };
}

/**
 * initializes local storage from user context if authenticated
 * and db value differs from local storage value
 */
function useInitializeZipFromUserContext(
  storedZip: ZipCode | null,
  setValue: (value: ZipCode) => void
) {
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user?.zipCode && user?.zipCode !== storedZip?.code) {
      setValue({ code: user?.zipCode });
    }
  }, [user]);
}
