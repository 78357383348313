import React, { Ref } from 'react';
import classNames from 'classnames';
import styles from './InputText.module.scss';

interface IInputTextProps {
  autoFocus?: boolean;
  className?: string;
  'data-test'?: string;
  disabled?: boolean;
  id?: string;
  isValid?: boolean;
  maxLength?: number;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  inputRef?: Ref<HTMLInputElement>;
  type?: string;
  value: string;
}

export default function InputText({
  autoFocus = false,
  className,
  disabled = false,
  id,
  isValid,
  inputRef = null,
  maxLength,
  onBlur = () => {},
  onChange,
  onFocus = () => {},
  onKeyPress = () => {},
  placeholder,
  type = 'text',
  value = '',
  'data-test': dataTest
}: IInputTextProps) {
  const inputClasses = classNames(
    styles['a-input-text'],
    className,
    { [styles['-is-valid']]: isValid === true },
    { [styles['-is-invalid']]: isValid === false },
  );
  return (
    <input
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      className={inputClasses}
      data-test={ dataTest || 'a-input-text'}
      disabled={disabled}
      id={id || 'a-input-text'}
      maxLength={maxLength}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onKeyPress={onKeyPress}
      placeholder={placeholder}
      type={type}
      value={value}
      ref={inputRef}
    />
  );
}
