import { useMutation } from '@apollo/client';
import UserContext from '@context/User/user-context';
import logger from '@lib/logger';
import { useContext, useState } from 'react';

import UpdateUserMutation, { IUpdateUserVariables, IUpdateUserData } from '@graphql/User/mutations/update-user.graphql';

interface IUpdateUserInput {
  zipCode: string;
}

const log = logger('useUpdateAuthenticatedUser');

export default function useUpdateAuthenticatedUser() {
  const [updateUserMutation] = useMutation<
    IUpdateUserData,
    IUpdateUserVariables
  >(UpdateUserMutation);
  const { user, setUser } = useContext(UserContext);
  const [busy, setBusy] = useState(false);

  return {
    busy,
    async updateAuthenticatedUser(input: IUpdateUserInput) {
      if (!user?.id) {
        log.warn('Cannot update authenticated user. Session is not authenticated.');
        return;
      }
      try {
        setBusy(true);
        const response = await updateUserMutation({
          variables: {
            userId: user.id,
            input
          }
        });

        if (response.data?.updateUser) {
          setUser({
            ...user,
            zipCode: response.data?.updateUser.zipCode
          });
        }
      } catch (error: any) {
        log.error({ input, error }, `Error updating authenticated user data`);
      } finally {
        setBusy(false);
      }
    }
  };
}
