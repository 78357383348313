import {
  // useEffect,
  useState,
  useContext
} from 'react';
import useLocalStorage from '@hooks/UseLocalStorage/UseLocalStorage';
import { useZipStorage, ZipCode } from '@components/shared/zipcode/ZipStoreProvider';
import UserContext, { IUser } from '@context/User/user-context';

const AT_HOME_MODAL_STORAGE_KEY = 'AtHomeModalFormState';

export interface IForm {
  name: string;
  email: string;
  zipCode: string;
  password?: string;
}

export interface IFormNotice {
  state: 'info' | 'error';
  message: string;
}

export enum AtHomeModalState {
  ZIP_CODE_DISPLAY = 'ZIP_CODE_DISPLAY',
  FULL_FORM = 'FULL_FORM',
  PASSWORD_FORM = 'PASSWORD_FORM',
  ZIP_CODE_FORM = 'ZIP_CODE_FORM'
}

export default function useAtHomeModalFormState() {
  const { value: form, setValue: setForm } = useLocalStorage<IForm>(AT_HOME_MODAL_STORAGE_KEY, {
    name: '', email: '', zipCode: ''
  });

  const [formNotice, setFormNotice] = useState<IFormNotice | null>(null);

  const { storedZip, clearZip } = useZipStorage();
  const { user } = useContext(UserContext);

  const [formState, setFormState] = useState<AtHomeModalState>(
    getInitialState(storedZip, user)
  );

  // useEffect(() => {
  //   if (storedZip) {
  //     setForm((f) => ({
  //       ...f,
  //       zipCode: storedZip.code
  //     }));
  //   }
  // }, [storedZip]);

  return {
    form,
    setForm: (_form: IForm) => {
      clearZip();
      setForm(_form);
    },
    formNotice,
    setFormNotice,
    formState,
    setFormState
  };
}

function getInitialState(storedZip: ZipCode | null, user: IUser | null) {
  if (storedZip) {
    return AtHomeModalState.ZIP_CODE_DISPLAY;
  }
  if (user) {
    return AtHomeModalState.ZIP_CODE_FORM;
  }

  return AtHomeModalState.FULL_FORM;
}
